var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-0"},[(_vm.loading)?_c('div',{staticClass:"d-flex align-center justify-center flex-column",staticStyle:{"min-height":"300px"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50","color":"grey"}}),_c('div',{staticClass:"py-2"},[_vm._v("Loading")])],1):_c('div',[(_vm.traceData)?_c('v-data-table',{staticClass:"elevation-2",attrs:{"search":_vm.filterText,"headers":_vm.headers,"items":_vm.traceData,"sort-by":['timestamp'],"sort-desc":[true],"item-key":"id","center":"","id":"trace-table","item-class":"pa-0 ma-0"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"pa-2 lighten-3"},[_c('v-text-field',{attrs:{"solo":"","hide-details":"","clearable":"","name":"filter","autocomplete":"filter","placeholder":"Filter configs","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1)]},proxy:true},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.timestamp)))])]}},{key:"item.method",fn:function(ref){
var item = ref.item;
return [(item.method)?_c('v-chip',[_c('b',[_vm._v(_vm._s(item.method))])]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{staticClass:"white--text",attrs:{"color":item.status == 200 ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e()]}},{key:"item.timeTaken",fn:function(ref){
var item = ref.item;
return [(item.timeTaken)?_c('span',[_vm._v(_vm._s(item.timeTaken / 1000.0)+"s")]):_vm._e()]}}],null,true)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }