<template>
  <div class="py-0">
    <div
      v-if="loading"
      class="d-flex align-center justify-center flex-column"
      style="min-height: 300px"
    >
      <v-progress-circular indeterminate size="50" color="grey" />
      <div class="py-2">Loading</div>
    </div>
    <div v-else>
      <v-data-table
        v-if="traceData"
        :search="filterText"
        :headers="headers"
        :items="traceData"
        :sort-by="['timestamp']"
        :sort-desc="[true]"
        item-key="id"
        center
        class="elevation-2"
        id="trace-table"
        item-class="pa-0 ma-0"
      >
        <template v-slot:top>
          <div class="pa-2 lighten-3">
            <v-text-field
              v-model="filterText"
              solo
              hide-details
              clearable
              name="filter"
              autocomplete="filter"
              placeholder="Filter configs"
              prepend-inner-icon="mdi-magnify"
            />
          </div>
        </template>
        <template v-slot:[`item.timestamp`]="{ item }">
          <span>{{ formatDate(item.timestamp) }}</span>
        </template>
        <template v-slot:[`item.method`]="{ item }">
          <v-chip v-if="item.method">
            <b>{{ item.method }}</b>
          </v-chip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            class="white--text"
            :color="item.status == 200 ? 'green' : 'red'"
            v-if="item.status"
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:[`item.timeTaken`]="{ item }">
          <span v-if="item.timeTaken">{{ item.timeTaken / 1000.0 }}s</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationHttpTraceCard",
  props: {
    link: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    filterText: null,
    axiosInstance: null,
    traceData: null,
  }),
  computed: {
    headers: () => [
      { text: "Timestamp", value: "timestamp", sortable: true, width: 200 },
      { text: "Method", value: "method", sortable: false, width: 80, align: "center" },
      { text: "Status", value: "status", sortable: false, width: 80, align: "center" },
      { text: "Path", value: "path", sortable: true },
      { text: "Origin", value: "origin", sortable: false, width: 125 },
      // { text: "Length", value: "length", sortable: true },
      { text: "Time Taken", value: "timeTaken", sortable: true, width: 125, align: "center" },
    ],
  },
  watch: {
    isActive() {
      this.fetchLatestTrace();
    },
  },
  mounted() {
    this.fetchLatestTrace();
  },
  methods: {
    async fetchLatestTrace() {
      if (!this.isActive) return;
      try {
        this.loading = true;
        const response = await this.$axios.get(this.link);
        let id = 1;
        response.data.traces.forEach((item) => {
          item.id = id++;
          item.timestamp = new Date(item.timestamp);
        });
        this.traceData = response.data.traces.map((item) => {
          return {
            timestamp: new Date(item.timestamp),
            method: item.request && item.request.method,
            origin: item.request && item.request.headers.origin && item.request.headers.origin[0],
            path: item.request && this.getRequestUrl(item),
            status: item.response && item.response.status,
            timeTaken: item.timeTaken || 0,
          };
        });
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
    getRequestUrl(item) {
      const headers = item.request.headers || {};
      const url = new URL(item.request.uri);
      const host = (headers["x-forwarded-host"] || [])[0];
      const proto = (headers["x-forwarded-proto"] || [])[0];
      const prefix = (headers["x-forwarded-prefix"] || [])[0];
      if (host && proto && prefix) {
        url.host = host;
        url.protocol = proto;
        url.pathname = prefix + url.pathname;
      }
      return "" + url;
    },
  },
};
</script>

<style scoped lang="scss"></style>
